/* ========================= */
/* ======  BACKGROUND  ===== */
/* ========================= */
.bg-indigo {
  background: var(--indigo);
}

.bg-semicircle {
  background-image: url('./images/bg-login.svg') !important;
  background-repeat: no-repeat !important;
  background-position-x: right !important;
}
.mini-logo {
  width: 38px;
}
.logo {
  width: 95px;
}
.mini-icon {
  width: 13px;
}
.icon-afb {
  display: block;
  width: 20px;
  height: 20px;
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center;
  float: left;
  margin-right: 16px;
}
.icon-process {
  display: block;
  width: 37px;
  height: 37px;
  background-image: url('./images/process.svg');
  background-repeat: no-repeat;
}
.icon-info {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url('./images/info.svg');
  background-repeat: no-repeat;
}
.icon-timer, .icon-reload {
  margin-left: 5px;
  display: block;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
}
.icon-timer {
  background-image: url('./images/time.svg');
}
.icon-reload {
  background-image: url('./images/reload.svg');
}
.icon-fr {
  float: right;
}

.icon-account {
  background-image: url('./images/charts.png');
}

.icon-user {
  background-image: url('./images/users.png');
}

.icon-plane {
  width: 135px;
}
.icon-mini-planes {
  height: 40px !important;
}
/* ========================= */
/* ======   MARGIN   ====== */
/* ========================= */
.m-0 {
  margin: 0;
}

.mr-5 {
  margin-right: 5px;
}

.m-auto {
  margin: 0 auto;
}

.mt-15-5{
  margin-top: 15.5px;
}
.mb-45{
  margin-bottom: 45px;
}
/* ========================= */
/* ======   PADDING   ====== */
/* ========================= */
/* Letf */
.p-5 {
  padding-left: 5px;
}
.pl-1-5 {
  padding-left: 1.5vw !important;
}
.pl-2-5 {
  padding-left: 2.5vw !important;
}
.pl-5 {
  padding-left: 5vw !important;
}
.pl-9 {
  padding-left: 9vw;
}
.pl16px {
  padding-left: 16px;
}

/* Right */
.pr-16px {
  padding-right: 16.3px !important;
}
.pr-0 {
  padding-right: 0;
}
.pr-5p {
  padding-right: 5px;
}

/* Both */
.p-0 {
  padding: 0;
}
.plr-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.plr-7-4 {
  padding-left: 7.4vw;
  padding-right: 7.4vw;
}
.plr-12-5 {
  padding-left: 12.5vw;
  padding-right: 12.5vw;
}
.plr-14-1 {
  padding-left: 14.1vw;
  padding-right: 14.1vw;
}
.prb-0 {
  padding-right: 0;
  padding-bottom: 0;
}
.pbt-3 {
  padding-top: 3vh !important;
  padding-top: 3vh !important;
}

/* Top */
.pt-0 {
  padding-top: 0px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pt-3 {
  padding-top: 24px !important;
}
.pt-3-5 {
  padding-top: 24px !important;
}
.pt-4-1 {
  padding-top: 4.1vh !important;
}
.pt-4-5 {
  padding-top: 4.5vh;
}
.pt-5 {
  padding-top: 5vh;
}
.pt-5-2 {
  padding-top: 5.2vh;
}
.pt-5-4 {
  padding-top: 5.4vh;
}
.pt-6-4 {
  padding-top: 6.4vh !important;
}
.pt-8-3 {
  padding-top: 8.3vh !important;
}
.pt-10-2 {
  padding-top: 10.2vh !important;
}
.pt-11-3 {
  padding-top: 11.3vh !important;
}
.pt-14 {
  padding-top: 14vh !important;
}
.pt-16px {
  padding-top: 16px;
}
.pt-22 {
  padding-top: 22vh !important;
}
.pt-26-4 {
  padding-top: 26.4vh;
}
.pt-50px {
  padding-top: 50px !important;
}
.pt-72px {
  padding-top: 72px;
}

/** Bottom */
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-5 {
  padding-bottom: 5vh;
}

/* ========================= */
/* ======     FONT     ===== */
/* ========================= */
.t-center {
  text-align: center;
}
.t-justify {
  text-align: justify;
}
.f-bold {
  font-weight: bold;
}
.f-helvetica {
  font-family: HelveticaNeue !important;
}
.f-small {
  font-size: 12px;
}
.t-justify {
  text-align: justify;
}
.f-underline {
  text-decoration: underline;
}

/* ========================= */
/* ======     LINK     ===== */
/* ========================= */
.link {
  text-decoration: none;
  cursor: pointer;
}
.aftHeader .aftHeaderCompany .aftHeaderList a li:hover {
  cursor: pointer;
  background: #edf0f4;
}
.aftHeader .aftHeaderCompany .aftHeaderList a li:hover span:first-child {
  color: #3743aa;
}

/* ========================= */
/* =====     FLEX     ====== */
/* ========================= */
/* Flex alineacion */
.flex-align-base {
  align-items: baseline;
}
.flex-column {
  flex-direction: column;
}
.flex-align-end {
  justify-content: flex-end !important;
}
.separator {
  width: 100%;
  border-bottom: 1px solid var(--manatee);
}

/* ========================= */
/* =====     ALIGN     ===== */
/* ========================= */
.justify-center {
  justify-content: center;
}

.hide {
  display: none;
}

/* ========================= */
/* =====     MODAL     ===== */
/* ========================= */
.box-shadow {
  box-shadow: 1px 12px 6px 1px #eeeeee;
}

.lightbox.loading {
  background: white;
}
.lightbox.loading > img {
  height: 100px !important;
}

/* ========================= */
/* =====     BUTTON     ==== */
/* ========================= */
.bigButton.disable:hover > button {
  background: #edf0f4 !important;
  color: #969BA0;
}
.w-230px {
  width: 230px;
}

.w-213px {
  width: 213px;
}

.w-200px {
  width: 200px;
}

.btn-center {
  margin: 0 auto;
}

/* ESPECIFICOS */
.confirm-change-password {
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  align-items: center;
}

.confirm-change-password p {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--charcoal);
}

.confirm-change-password Button {
  width: 300px;
}

.card-home {
  width: 23vw;
  margin-top: 10vh;
}

.mTransfer {
  overflow: hidden !important;
}
.mTransfer table {
  width: 140% !important;
}

.table-terms {
  border: 1px solid #000000;
  border-spacing: 0px;
  border-collapse: collapse;
}

.table-terms th,
.table-terms td {
  text-align: left;
  vertical-align: top;
  border: 1px solid #000000;
}

.table-terms th {
  text-align: center;
}

.table-terms tr :first-child {
  padding-left: 16px;
}

.tb-afb .MuiPaper-root,
.tb-afb .MuiPaper-root > div {
  min-height: calc(100vh - 250px) !important;
  max-height: calc(100vh - 250px) !important;
}
.tb-afb .MuiPaper-root .divMuiTableFooter-root {
  font-size: 12px;
}

.tb-afb.contacts .MuiPaper-root,
.tb-afb.contacts .MuiPaper-root > div {
  min-height: calc(100vh - 379px) !important;
  max-height: calc(100vh - 379px) !important;
}
.tb-afb.contacts .MuiPaper-root > div td {
  font-size: 14px !important;
}
.tb-afb.contacts .MuiPaper-root > div td {
  padding: 4px;
}
.tb-afb.transfer .MuiPaper-root > div td {
  padding: 14px;
}
.tb-afb.movements .MuiPaper-root,
.tb-afb.movements .MuiPaper-root > div {
  min-height: calc(100vh - 413px) !important;
  max-height: calc(100vh - 413px) !important;
}
.w-13 {
  width: 13%;
}
/* ========================= */
/* =====  MENU_CONTACT  ==== */
/* ========================= */
.MenuList {
  font-family: Muli !important;
  font-size: 12px;
}

.no-result {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

/* ========================= */
/* ==  DATEPICKER FLOAT  === */
/* ========================= */
.datepicker-float {
  margin: 0px;
  position: absolute;
  z-index: 999;
}

/* ========================= */
/* ========  CARD  ========= */
/* ========================= */
.card > .scroll-table {
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}

.line-header {
  width: 100%;
  text-align: left;
  margin-left: 0;
}

.table-account {
  overflow: auto;
  border-radius: 4px;
  border: 1px solid #808489;
}

.table-account td {
  padding: 10px;
  border: 1px solid #808489;
}

.card.card-over {
  position: absolute;
  width: 100%;
  z-index: 11;
  opacity: 0.8;
}

.MuiSnackbar-root.sunset_background .MuiSnackbarContent-root {
  background: inherit;
}

@keyframes modal-scale {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes modal-hidde {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes content-modal-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.show-modal {
  animation: modal-scale 0.4s cubic-bezier(0.54, 0.01, 0, 1);
  animation-fill-mode: both;
  max-height: 650px;
  overflow-y: auto;
}
.show-modal > * {
  animation: content-modal-show 0.2s;
  animation-delay: 0.2s;
  animation-fill-mode: both;
  opacity: 0;
}

.hidde-modal > * {
  animation: modal-hidde 0.1s !important;
}

.transfer-multiple-summary small {
  padding: 0;
  display: block;
  margin-bottom: 4px;
}
.transfer-multiple-summary h2 {
  padding: 0;
  display: block;
  margin-bottom: 4px;
}

.MuiSnackbar-root {
  bottom: 0 !important;
  padding: 0 !important;
  width: max-content;
}
.MuiSnackbar-root > div {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  max-width: 600px;
  min-width: 400px;
}

.logoNav {
  height: 55px !important;
}

.buttonText {
  text-transform: none !important;
  margin-top: 7px !important;
}

.closeButton {
  position: absolute;
  right: 1;
  top: 1;
  margin-left: 64%;
}

.contentModal {
  height: 370px;
  padding-bottom: 40px;
}

.imageModal {
  width: 206px;
}

.gridCell {
  height: auto !important;
}

.newMainLogoNav {
  background: white !important;
  padding: 0px !important;
}

.buttonModal {
  width: 160px !important;
}

.logoExtra {
  width: 53px !important;
  height: 53px !important;
}
.inp-hidden {
  width: 0;
  height: 0;
  padding: 0 !important;
  overflow: hidden;
}