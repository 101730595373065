/*OVERRIDES*/
.lightbox .card {
  padding: 40px;
}
.sideBar li {
  transition: 0.1s, box-shadow 0.2s !important;
  box-shadow: 0px 0px #3e3e42 inset;
}

.sideBar li:active {
  opacity: 1;
}

.sideBar li.active {
  border-left: none;
  padding-left: 16px;
  box-shadow: 6px 0px #3e3e42 inset;
}

.tabBox ul {
  justify-content: flex-start;
  border-bottom: 1px solid #edf0f4;
  padding-bottom: 0;
  margin-bottom: 24px;
}

.tabBox li {
  transition: 0.2s;
  border-bottom: none;
}

.tabBox li.active {
  border-bottom: none;
  box-shadow: 0px -2px #3743aa inset !important;
}
.tabBox li:hover {
  border-bottom: none;
  box-shadow: 0px -1px #3743aa inset;
}

.tabBox li {
  max-width: 240px;
}

@media only screen and (min-width: 30em) {
  [class*='size-'] .inputGroup input,
  [class*='size-'] .inputGroup select,
  .lightbox .inputGroup input,
  .lightbox .inputGroup select {
    margin-bottom: 0;
  }
}

.aftHeader {
  padding: 24px;
}

.MuiIconButton-root {
  padding-left: 12px !important;
}

.input-code input {
  transition: 0.2s;
  margin-right: 12px;
}

.input-code input:focus {
  border: #3743aa 1px solid !important;
}

.input-code input:hover {
  border: #3e3e42 1px solid;
}

.mainNav {
  box-shadow: 0px 0px 8px 5px #00000005;
}

/*INPUT OVERRIDE*/

input,
.input,
select {
  transition: border 0.2s;
  border-radius: 3px;
}
input:hover,
.input:hover,
select:hover {
  border: #3e3e42 1px solid;
}

@media only screen and (min-width: 30em) {
  [class*='size-'] .inputGroup input.error ~ .inputLabel,
  [class*='size-'] .inputGroup select.error ~ .inputLabel,
  .lightbox .inputGroup input.error ~ .inputLabel,
  .lightbox .inputGroup select.error ~ .inputLabel {
    color: #972125;
  }
  [class*='size-'] .inputGroup input ~ .inputLabel,
  [class*='size-'] .inputGroup select ~ .inputLabel,
  .lightbox .inputGroup input ~ .inputLabel,
  .lightbox .inputGroup select ~ .inputLabel {
    transition: 0.3s cubic-bezier(0.73, -0.01, 0, 1.01);
    color: #3743aa;
    opacity: 0;
    position: relative;
    height: 0px;
    top: -58px;
    transform: translateY(4px);
    font-size: 12px;
    left: 16px;
    color: #3743aa;
  }
  [class*='size-'] .inputGroup input:focus ~ .inputLabel,
  [class*='size-'] .inputGroup input:not(:placeholder-shown) ~ .inputLabel,
  [class*='size-'] .inputGroup select:focus ~ .inputLabel,
  [class*='size-'] .inputGroup select:not(:placeholder-shown) ~ .inputLabel,
  .lightbox .inputGroup input:focus ~ .inputLabel,
  .lightbox .inputGroup input:not(:placeholder-shown) ~ .inputLabel,
  .lightbox .inputGroup select:focus ~ .inputLabel,
  .lightbox .inputGroup select:not(:placeholder-shown) ~ .inputLabel {
    opacity: 1;
    top: -58px;
    transform: translateY(0);
  }

  [class*='size-'] .inputGroup input:not(:placeholder-shown),
  [class*='size-'] .inputGroup select:not(:checked),
  .lightbox .inputGroup input:not(:placeholder-shown),
  .lightbox .inputGroup select:not(:checked) {
  }

  [class*='size-'] .inputGroup input:not(:placeholder-shown) ~ .inputLabel,
  [class*='size-'] .inputGroup select ~ .inputLabel,
  .lightbox .inputGroup input:not(:placeholder-shown) ~ .inputLabel,
  .lightbox .inputGroup select ~ .inputLabel {
  }

  [class*='size-'] .inputGroup input:not(:placeholder-shown),
  [class*='size-'] .inputGroup select:not(:checked),
  .lightbox .inputGroup input:not(:placeholder-shown),
  .lightbox .inputGroup select:not(:checked) {
  }

  [class*='size-'] .inputGroup input:hover,
  [class*='size-'] .inputGroup select:hover,
  .lightbox .inputGroup input:not(:placeholder-shown):hover,
  .lightbox .inputGroup select:hover {
    border: #3e3e42 1px solid;
  }

  [class*='size-'] .inputGroup input:hover ~ .inputLabel,
  [class*='size-'] .inputGroup select ~ .inputLabel,
  .lightbox .inputGroup input:hover ~ .inputLabel,
  .lightbox .inputGroup select ~ .inputLabel {
    transition: 0.1s;
    color: #3e3e42;
  }

  [class*='size-'] .inputGroup input:focus ~ .inputLabel,
  [class*='size-'] .inputGroup select ~ .inputLabel,
  .lightbox .inputGroup input:focus ~ .inputLabel,
  .lightbox .inputGroup select ~ .inputLabel {
    transition: 0.3s cubic-bezier(0.73, -0.01, 0, 1.01);
    color: #3743aa;
  }
  [class*='size-'] .inputGroup input.error ~ .inputLabel,
  [class*='size-'] .inputGroup select.error ~ .inputLabel,
  .lightbox .inputGroup input.error ~ .inputLabel,
  .lightbox .inputGroup select.error ~ .inputLabel {
    color: #972125;
  }

  [class*='size-'] .inputGroup input:focus,
  [class*='size-'] .inputGroup select:focus,
  .lightbox .inputGroup input:focus,
  .lightbox .inputGroup select:focus {
    border: 1px solid #3743aa;
  }

  /* [class*='size-']
    .inputGroup
    input:matches(:not(:placeholder-shown), :focus)
    ~ .inputLabel,
  [class*='size-'] .inputGroup select ~ .inputLabel,
  .lightbox
    .inputGroup
    input:matches(:not(:placeholder-shown), :focus)
    ~ .inputLabel,
  .lightbox .inputGroup select ~ .inputLabel {
    color: #3743aa !important;
  }*/
}

.inputGroup input:disabled {
  background-color: -internal-light-dark(
    rgba(239, 239, 239, 0.3),
    rgba(59, 59, 59, 0.3)
  );
  border-color: rgba(118, 118, 118, 0.5) !important;
  color: rgba(118, 118, 118, 0.5) !important;
}

.inputGroup input:disabled ~ .inputLabel {
  color: rgba(118, 118, 118, 0.5) !important;
}

.search-input button {
  width: auto;
  padding: 15px;

  border: 0;

  font-weight: bold;
  border-radius: 0 3px 3px 0;
  position: absolute;
  top: -1px;
  right: 0px;
  border: 1px solid #3743aa;
  top: 0;
  /* bottom: 0; */
  margin: auto;
  height: 48px;
  border: none;
  background: transparent;
  color: #969BA0;
  cursor: initial;
  cursor: pointer;
  box-shadow: -1px 0px #eef1f4;
}

.search-input button:focus {
  outline: none;
}

.search-input button:hover {
  background: transparent;
  color: #969BA0;
}
.search-input label:hover ~ button {
  color: #969BA0;
  background: transparent;
  background: transparent;
}

.search-input input:hover ~ button {
  color: #969BA0;
  background: transparent;
  background: transparent;
}

.search-input input:focus ~ button {
  background: transparent;
  color: #3743aa;
}

.search-input input {
  padding-right: 83px;
}

.search-input input:not(:placeholder-shown) ~ button {
  color: #3743aa;
}

/**/

@media (max-width: 992px) {
  .lightbox .card div:first-child,
  .lightbox .card div:last-child,
  .lightbox .card h3,
  .lightbox .card h3,
  .lightbox .card h1,
  .lightbox .card p {
    padding: 0;
    padding: 4px;
  }
}

button {
  transition: 0.1s;
}

.MuiTableCell-root {
  padding: 20px 8px;
}

.MuiTableRow-head th {
  padding: 16px 16px;
  padding-bottom: 12px;
  font-weight: bold;
  color: #969BA0;
}

.Component-horizontalScrollContainer-5 > div > div {
  overflow-y: inherit !important;
}

.bigButton {
  font-size: 16px;
}

.bigButton > button {
  padding: 15px;
}

.money-input {
  display: flex;
  flex-direction: column-reverse;
}

.money-input input {
  border: none;
  border-bottom: #edf0f4 solid 1px;
}

.money-input input:hover {
  border: none;
  border-bottom: #3e3e42 solid 1px;
}

.money-input input:focus,
.money-input input:not(:placeholder-shown) {
  border: none;
  border-bottom: #3743aa solid 1px;
}

.money-input input ~ * {
  transition: 0.1s;
}

.money-input input:focus ~ *,
.money-input input:not(:placeholder-shown) ~ * {
  color: #3743aa;
}

.money-input input.error {
  border-bottom: #972125 solid 1px;
  border-top: none;
}

.money-input input.error ~ * {
  color: #972125;
}
